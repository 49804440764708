@media (max-width: 991px) {
  .pcf-nav ul.navbar-nav li.nav-item {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .pcf-flyout-panel {
    overflow: auto;
  }
}

@media (min-width: 768px) {
  #ncsc-header > div.pcf-flyout-panel {
    display: none;
  }
}

.pcf-flyout-panel .pcf-flyout-panel-overflow-container {
  overflow: auto !important;
}
.overlay {
  background-color: #efefef;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  top: 0px;
  left: 0px;
  opacity: 0.5; /* in FireFox */
  filter: alpha(opacity=50); /* in IE */
}

.pcf-flyout-panel {
  overflow: auto;
}

#web-form .pcf-flyout-panel .pcf-flyout-panel-controls {
  display: none;
}

#submitting-warning {
  color: #565656;
}

#tsAndCsDiv {
  padding-top: 2em;
  padding-bottom: 2em;
}

.fas.fa-check,
.fas.fa-times {
  padding-right: 0.5em;
}

.fa-ul {
  padding-top: 0.5em;
}

.fa-ul > li {
  padding: 0.2em;
}

input.form-control:focus,
select.form-control:focus,
textarea.form-control:focus {
  box-shadow: none;
  border: 1.5px solid #2a7896;
}

#organisation-picker div[role="alert"] {
  padding: 0 !important;
}
