body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333333;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-size: 10em;
}
h2 {
  font-size: 3em;
}

p {
  line-height: 0.1em;
}

.vh-100 {
  height: 100vh !important;
}
.centered-content {
  text-align: center;
  align-content: center;
  vertical-align: middle;
  display: inline;
}

.uuidText {
  font-size: 0.8em;
}
h2 a {
  color: inherit;
}
h2 a:hover {
  color: inherit;
  text-decoration: none;
}
.indi-button-container {
  position: fixed;
  top: 1em;
  right: 1em;
}
.status-badge {
  width: 5em;
}

button:focus {
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}
